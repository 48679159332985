import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import CompleteModalIcon from "../Assets/Images/CompleteModalIcon";
import TimeupIcon from "../Assets/Images/TimeupIcon";
import { DobComponent, FormInput } from "../Component";
import { Formik, Form } from "formik";
import DobCalendar from "../Component/DobCalendar";
import CalenderIcon from "../Assets/Images/CalenderIcon";
import moment from "moment";
import ArrowDown from "../Assets/Images/ArrowDown";
import * as data from "../Constant/countries";
import { saveMajor, searchCountries } from "../Services/Function";
import * as Yup from "yup";
import Loader from "../Component/Loader/Loader";
import PhoneInput from "react-phone-number-input";
import "react-phone-input-2/lib/bootstrap.css";
import { CountryInputComponent } from "../Component/CountryInputComponent";
import { debounce } from "debounce";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { dataAction } from "../state";

export default function CompleteModal(props) {
  const [countryCode, setCountryCode] = useState("+965");
  const [phoneNo, setphoneNo] = useState("");
  const [ErrorPhone, setErrorPhone] = useState(false);
  const [nameErr, setnameErr] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(2000, 11 - 1, 12));
  const [ErrorDob, setErrorDob] = useState(false);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [emailErr, setemailErr] = useState(false);
  const [countrySelErr, setcountrySelErr] = useState(false);
  const [date, setdate] = useState(null);
  const [selectedCountry, setselectedCountry] = React.useState({});
  const [countries, setcountries] = React.useState([]);
  const [searchText, setsearchText] = React.useState("");

  const [loading, setloading] = useState(false);
  let history = useNavigate();
  const location = useLocation();
  let destination = location.state.country;

  const dispatch = useDispatch();
  const { showMajorAnimation } = bindActionCreators(dataAction, dispatch);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let utm_source = params.get("utm_source");
  let utm_content = params.get("utm_content");
  let utm_medium = params.get("utm_medium");
  let utm_campaign = params.get("utm_campaign");
  let utm_term = params.get("utm_term");

  const pickHighest = (obj, num = 1) => {
    const requiredObj = {};
    if (num > Object.keys(obj).length) {
      return false;
    }
    Object.keys(obj)
      .sort((a, b) => obj[b] - obj[a])
      .forEach((key, ind) => {
        if (ind < num) {
          requiredObj[key] = obj[key];
        }
      });
    return requiredObj;
  };
  const searchCountry = debounce(async (e) => {
    if (!e.target.value) {
      setcountries([]);
    } else {
      const res = await searchCountries({
        text: e.target.value,
        sortBy: "createdAt:desc",
        limit: 7,
      });
      setcountries(res?.results);
    }
  }, 500);
  let majors = Object.keys(pickHighest(props.data, 5));
  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        name: "",
        email: "",
        countrySelErr: "",
        date: "",
        phoneNo: "",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        function ValidateEmail(mail) {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail)) {
            setemailErr(false);
            return false;
          } else {
            setemailErr(true);
            return true;
          }
        }
        let phoneErr = false;

        if (!name) {
          setnameErr(true);
        }
        ValidateEmail(email);
        // const phoneLength = countryCode === "+965" ? 8 : 10;

        if (!phoneNo.length) {
          setErrorPhone(true);
          phoneErr = true;
        }
        if (!selectedDate) {
          setErrorDob(true);
        }
        if (!selectedCountry) {
          setcountrySelErr(true);
        }
        if (
          selectedCountry &&
          selectedDate &&
          !phoneErr &&
          !ValidateEmail(email) &&
          name
        ) {
          setloading(true);

          await saveMajor({
            majors: majors,
            selectedAns: props.selectedQuestionArr
              .sort(function (a, b) {
                return a.question - b.question;
              })
              .map((x) => x.ans),
            name: name,
            timeTaken: "",
            email: email,
            phoneNo: phoneNo.slice(countryCode.length),
            countryCode: countryCode,
            dob: selectedDate,
            createdAt: Date.now(),
            nationality: selectedCountry.english_name,
            destination: destination,
            source: utm_source,
            campaign: utm_medium,
            medium: utm_campaign,
            content: utm_content,
          });
          showMajorAnimation(true);
          await history(
            props.query ? `/test-results${props.query}` : "/test-results",
            {
              state: { data: majors, destination: destination },
            }
          );
          props.onHide();
        }
      }}
    >
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Modal heading
      </Modal.Title>
    </Modal.Header> */}{" "}
        <Modal.Body
          className="complete-modal-body"
          style={{ position: "relative" }}
        >
          <div
            className={`d-flex flex-column ${"align-items-end"}`}
            style={{ paddingBottom: 24 }}
          >
            {!props.timeEnd ? <CompleteModalIcon /> : <TimeupIcon />}
            <span className="complete-modal-header">
              لقد أنهيت اختبار التخصص
            </span>
            <span className="complete-modal-subheader">
              يرجى إكمال البيانات التالية للحصول على النتيجة
            </span>
          </div>
          <Form>
            <FormInput
              noLabel
              placeholder="الاسم الثلاثي (بالانجليزي)"
              name="name"
              onChange={(e) => {
                setname(e.target.value);
                setnameErr(false);
              }}
              type="text"
              style={{
                borderRadius: "200px",
                background: "rgba(217, 217, 217, 0.30)",
                borderColor: nameErr ? "rgb(253, 62, 96)" : "#eae9f2",
                marginBottom: 32,
              }}
            />
            {nameErr ? <div style={style.error}> الرجاء إدخال اسم</div> : null}
            <PhoneInput
              international={false}
              defaultCountry="KW"
              placeholder="رقم الهاتف"
              value={phoneNo}
              className={"phoneInput-ar"}
              onChange={(e) => setphoneNo(e)}
              // countryCallingCode={false}
              style={{
                marginBottom: 32,
                // direction: arabic ? "rtl" : "ltr",
              }}
            />
            {/* <PhoneInput
              country={"kw"}
              value={phoneNo}
              copyNumbersOnly={true}
              countryCodeEditable={false}
              // inputClass="input-form"
              inputStyle={{ textAlign: "right", marginBottom: 10 }}
              onChange={(value, data, event, formattedValue) => {
                setCountryCode(data.dialCode);
                // const phone = value.slice(data.dialCode.length);
                setphoneNo(value);
                setErrorPhone(false);
              }}
              placeholder="رقم الموبايل"
            /> */}
            {ErrorPhone ? (
              <div style={{ marginBottom: 12, float: "right", marginTop: -15 }}>
                <span
                  style={{
                    color: "#FD3E60",
                    fontWeight: "normal",
                    fontSize: 12,
                    fontFamily: "Cairo",
                    direction: "rtl",
                  }}
                >
                  الرجاء ادخال رقم صحيح
                </span>
              </div>
            ) : null}
            <div style={{ marginBottom: 20 }}>
              <DobComponent
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </div>

            <FormInput
              noLabel
              onChange={(e) => {
                setemail(e.target.value);
                setemailErr(false);
              }}
              style={{
                borderRadius: "200px",
                background: "rgba(217, 217, 217, 0.30)",
                borderColor: emailErr ? "rgb(253, 62, 96)" : "#eae9f2",
                marginBottom: 32,
              }}
              placeholder="الإيميل"
              name="email"
              type="text"
            />
            {emailErr ? (
              <div style={style.error}> الرجاء إدخال البريد الإلكتروني</div>
            ) : null}

            {ErrorDob ? (
              <div style={{ ...style.error, marginTop: 0 }}>
                {" "}
                الرجاء إدخال تاريخ الميلاد.
              </div>
            ) : null}

            <CountryInputComponent
              selectedCountry={selectedCountry}
              setsearchText={setsearchText}
              setselectedCountry={setselectedCountry}
              arabic={true}
              submitForm
              completeModal
              recommendedCountries={[]}
              searchCountry={searchCountry}
              countries={countries}
              setcountries={setcountries}
              searchText={searchText}
              placeholder={`${t("common:nationalityPlaceholder")}`}
            />

            {countrySelErr ? (
              <div style={{ ...style.error, marginTop: 0 }}>
                الرجاء اختيار الجنسية
              </div>
            ) : null}
            <Modal.Footer
              className="d-flex justify-content-start"
              style={{ border: "none", padding: 0 }}
            >
              <Button
                disabled={loading}
                style={{ width: "228px", border: "none" }}
                className="custom-black-btn"
                type="submit"
              >
                {loading ? "Loading..." : "الحصول على نتيجة الاختبار"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </Formik>
  );
}

const style = {
  error: {
    color: "rgb(253, 62, 96)",
    fontWeight: "normal",
    fontSize: 12,
    direction: "rtl",
    fontFamily: "Cairo",
    marginTop: -10,
    marginBottom: 12,
    textAlign: "right",
  },
};
